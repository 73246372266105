






























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { StringHelper } from '@/utils/helpers/string-helper';
import ConversationMessageModel from '@/models/crm/conversation-message.model';
import { ConversationMessageStatusEnum } from '@/enums/crm/conversation-message-status.enum';
import { ConversationMessageOriginEnum } from '../../enums/crm/conversation-message-origin.enum';
import { ConversationMessageTypeEnum } from '@/enums/crm/conversation-message-type.enum';
import { ConversationMessageDeveloperMessageEnum } from '@/enums/crm/conversation-message-developer-message.enum';
import ConversationTemplateModel from '@/models/crm/conversation-template.model';
import CrmChatDialogMessageContent from './chat-dialog-message-content.vue';

@Component({
  components: {
    CrmChatDialogMessageContent,
  },
})
export default class CrmChatDialogMessage extends Vue {
  @Prop({ required: true })
  message!: ConversationMessageModel;

  @Prop({ default: false })
  blocked!: boolean;

  @Prop()
  replyMode!: boolean;

  @Prop({ default: () => [] })
  templates!: ConversationTemplateModel[];

  @Prop()
  eagerLoadImage!: boolean;

  @Prop()
  enableActions!: boolean;

  @Prop()
  large!: boolean;

  @Prop({ default: 'chat-bubble' })
  classes!: string;

  messageTypes = ConversationMessageTypeEnum;

  messageStatuses = ConversationMessageStatusEnum;

  retryIcon = 'mdi-cached';

  btnRetry = false;

  @Emit('retry-text')
  onRetrySendText(): ConversationMessageModel {
    return this.message;
  }

  @Emit('retry-template')
  onRetrySendTemplate(): ConversationTemplateModel | undefined {
    if (this.message.content.data.template) {
      const template = this.templates.find((t) => t.waTemplateName
        === this.message.content.data.template.Name as string);
      return template;
    }
    return undefined;
  }

  onImageStartLoad(): void {
    this.$emit('image-start-load', this.message.id);
  }

  onImageLoaded(): void {
    this.$emit('image-loaded', this.message.id);
  }

  onDeleteMessage(): void {
    this.$emit('delete', this.message);
  }

  onClickRetryBtn(): void {
    this.btnRetry = !this.btnRetry;
    this.message.errorCode = -1;
    if (this.btnRetry) {
      if (this.message.type.code === ConversationMessageTypeEnum.Template) {
        this.onRetrySendTemplate();
      } else {
        this.onRetrySendText();
      }
    }
  }

  static getErrorJSONMessages(errorMessage: string) : number {
    const jsonObject = errorMessage.slice(errorMessage.indexOf('{'), errorMessage.lastIndexOf('}'));
    const data = JSON.parse(jsonObject);
    return data.meta.http_code;
  }

  get isMediaMessage(): boolean {
    const isAudio = this.message.type.code === ConversationMessageTypeEnum.Audio;
    const isImage = this.message.type.code === ConversationMessageTypeEnum.Image;
    const isVideo = this.message.type.code === ConversationMessageTypeEnum.Video;
    const isDocument = this.message.type.code === ConversationMessageTypeEnum.Document;
    const isVoice = this.message.type.code === ConversationMessageTypeEnum.Voice;

    return isAudio || isImage || isVideo || isDocument || isVoice;
  }

  get developerMessageAux(): string {
    return StringHelper.getNSentences(this.message.developerMessage, 2).toLowerCase();
  }

  get developerMessageIcon(): string {
    switch (this.developerMessageAux) {
      case ConversationMessageDeveloperMessageEnum.NoFunds:
        return 'mdi-currency-usd-circle';
      default:
        return '';
    }
  }

  get statusMessageIcon(): string {
    if (this.developerMessageIcon === '') {
      switch (this.message.status.code) {
        case ConversationMessageStatusEnum.Dispatched:
        case ConversationMessageStatusEnum.Sent:
          return 'mdi-check';
        case ConversationMessageStatusEnum.Delivered:
        case ConversationMessageStatusEnum.Read:
        case ConversationMessageStatusEnum.Received:
          return 'mdi-check-all';
        case ConversationMessageStatusEnum.Created:
        case ConversationMessageStatusEnum.Error:
        case ConversationMessageStatusEnum.Failed:
        case ConversationMessageStatusEnum.TemporaryInError:
          return 'mdi-alert-circle';
        case ConversationMessageStatusEnum.Deleted:
          return 'mdi-delete';
        case ConversationMessageStatusEnum.Temporary:
          return 'mdi-refresh';
        default:
          return '';
      }
    }
    return this.developerMessageIcon;
  }

  get developerMessageIconColor(): string {
    switch (this.developerMessageAux) {
      case ConversationMessageDeveloperMessageEnum.NoFunds:
        return 'orange';
      default:
        return '';
    }
  }

  get statusMessageIconColor(): string {
    if (this.developerMessageIconColor === '') {
      switch (this.message.status.code) {
        case ConversationMessageStatusEnum.Read:
        case ConversationMessageStatusEnum.Received:
          return 'primary';
        case ConversationMessageStatusEnum.Created:
          return 'orange';
        case ConversationMessageStatusEnum.Error:
        case ConversationMessageStatusEnum.Failed:
        case ConversationMessageStatusEnum.TemporaryInError:
          return 'red';
        default:
          return 'dark';
      }
    }
    return this.developerMessageIconColor;
  }

  get developerMessage(): string {
    switch (this.developerMessageAux) {
      case ConversationMessageDeveloperMessageEnum.NoFunds:
        return this.$t('crm.chat.messages.noFundsError').toString();
      default:
        return '';
    }
  }

  get statusMessage(): string {
    if (this.developerMessage === '') {
      if (this.message.errorCode) {
        if (this.message.errorCode === -1) {
          this.message.errorCode = CrmChatDialogMessage.getErrorJSONMessages(this.message.errorMessage);
        }

        const messageKey = `crm.chat.messages.error${this.message.errorCode}`;
        let errorMessage = this.$t(messageKey);

        if (errorMessage === messageKey) {
          errorMessage = this.message.errorMessage;
        }

        return `${errorMessage}`;
      }

      if (this.isMediaMessage && this.message.status.code === ConversationMessageStatusEnum.TemporaryInError) {
        return this.$t(`crm.chat.status.${this.message.status?.code}Image`).toString();
      }

      switch (this.message.status.code) {
        case ConversationMessageStatusEnum.Created:
        case ConversationMessageStatusEnum.TemporaryInError:
          return this.$t(`crm.chat.status.${this.message.status?.code}`).toString();
        default:
          return this.message.status?.name;
      }
    }
    return this.developerMessage;
  }

  get statusMessageAnimated(): boolean {
    return this.message.status?.code === ConversationMessageStatusEnum.Temporary;
  }

  get retryMessage(): string {
    return this.$t('crm.chat.retry').toString();
  }

  get isIncoming(): boolean {
    return this.message.origin.id === ConversationMessageOriginEnum.Incoming;
  }

  get isOutgoing(): boolean {
    return this.message.origin.id === ConversationMessageOriginEnum.Outgoing;
  }

  get isTemporary(): boolean {
    const temporary = this.message.status.code === ConversationMessageStatusEnum.Temporary;
    return temporary;
  }

  get isTemporaryInError(): boolean {
    const temporaryInError = this.message.status.code === ConversationMessageStatusEnum.TemporaryInError;
    return temporaryInError;
  }

  get isInactive(): boolean {
    const deleted = this.message.status.code === ConversationMessageStatusEnum.Deleted;
    const error = this.message.status.code === ConversationMessageStatusEnum.Error;
    const failed = this.message.status.code === ConversationMessageStatusEnum.Failed;
    const temporaryInError = this.message.status.code === ConversationMessageStatusEnum.TemporaryInError;
    return deleted || error || failed || temporaryInError;
  }

  get showStatusIcon(): boolean {
    return !!this.statusMessageIcon && (this.isOutgoing || this.isInactive);
  }
}
